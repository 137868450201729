/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"

import Footer from "../components/footer"
import SEO from "../components/seo"
import Layout from "../components/layout"
import dayjs from "dayjs"

const Events = ({ data }) => {
  const eventPages = data.allWpEventPage.nodes
  const simpleEventPages = data.allWpSimpleEvent.nodes
  const meetupEventPages = data.allWpMeetupEvent.nodes

  let today = new Date()

  meetupEventPages.map(e =>
    e.startMeridian === "pm"
      ? (e.startTime = parseInt(e.startTime) + 12)
      : e.startTime
  )

  meetupEventPages.map(
    e =>
      (e.eventStartDate = new Date(
        e.startDate +
          "T" +
          e.startTime +
          ":" +
          e.startMinute +
          ":00" +
          e.timezoneDiff
      ))
  )

  const together = eventPages.concat(meetupEventPages, simpleEventPages)

  const sortedArray = together.sort(
    (a, b) =>
      new Date(b.startDate || b.cYAN?.start || b.simpleEvents.start) -
      new Date(a.startDate || a.cYAN?.start || a.simpleEvents.start)
  )

  const finalArray = sortedArray.filter(e => {
    return !e?.content?.includes("#@#")
  })

  const eventFilter = eventPages.sort(
    (a, b) => new Date(b.cYAN?.start) - new Date(a.cYAN?.start)
  )

  const simpleFilter = simpleEventPages.sort(
    (a, b) => new Date(b.simpleEvents?.start) - new Date(a.simpleEvents?.start)
  )

  const meetupFilter = meetupEventPages.sort(
    (a, b) => new Date(b.startDate) - new Date(a.startDate)
  )

  const dateInPast = (firstDate, secondDate) => firstDate < secondDate

  return (
    <Layout>
      <SEO
        title="Upcoming Events | tinyML Foundation"
        description="TinyML Summit. The topic is advances in ultra-low power Machine Learning technologies and applications."
      />
      <div className="grid-container gap-xxxl" sx={{ paddingTop: `150px` }}>
        <h2 sx={{ marginBottom: `2rem` }}>All Events</h2>
        <div className="grid-x grid-margin-x grid-margin-y event-overview">
          {finalArray?.map(e => {
            return (
              <div
                key={e?.id}
                className="large-4 medium-6 small-12 cell"
                sx={{
                  color: `#3a5d7f`,
                  boxShadow: `0px 0px 60px rgb(0 0 0 / 8%)`,
                  background: `#fff`,
                  textAlign: `center`,
                }}
              >
                <Link to={`/event/${e?.slug}`}>
                  <Img
                    sx={{ height: `100%` }}
                    fluid={
                      e.cYAN?.featuredImage?.localFile?.childImageSharp
                        ?.fluid ||
                      e.featuredImage?.node?.localFile?.childImageSharp
                        ?.fluid ||
                      e.simpleEvents?.heroImage?.localFile?.childImageSharp
                        .fluid ||
                      data.placeholder.childImageSharp.fluid
                    }
                  />
                </Link>
                <div
                  sx={{
                    height: `14rem`,
                    display: `flex`,
                    flexDirection: `column`,
                  }}
                >
                  {e?.title ? (
                    <h4
                      sx={{
                        fontSize: `20px`,
                      }}
                    >
                      <Link to={`/event/${e?.slug}`}>{e?.title}</Link>
                    </h4>
                  ) : null}
                  {e?.cYAN?.start ? (
                    <p sx={{ color: `#faa23a;`, fontStyle: `italic` }}>
                      {e?.cYAN?.start === e?.cYAN?.end
                        ? dayjs(e?.cYAN?.start).format("MMM D, YYYY")
                        : `${dayjs(e?.cYAN?.start).format("MMM D, YYYY")} -
                        ${dayjs(e?.cYAN?.end).format("MMM D, YYYY")}`}
                    </p>
                  ) : null}
                  {e?.startDate ? (
                    <p sx={{ color: `#faa23a;`, fontStyle: `italic` }}>
                      {e?.startDate === e?.endDate
                        ? dayjs(e?.startDate).format("MMM D, YYYY")
                        : `${dayjs(e?.startDate).format("MMM D, YYYY")} -
                      ${dayjs(e?.endDate).format("MMM D, YYYY")}`}
                    </p>
                  ) : null}
                  {e?.simpleEvents?.start ? (
                    <p sx={{ color: `#faa23a;`, fontStyle: `italic` }}>
                      {e?.simpleEvents?.start === e?.simpleEvents?.end
                        ? dayjs(e?.simpleEvents?.start).format("MMM D, YYYY")
                        : `${dayjs(e?.simpleEvents?.start).format(
                            "MMM D, YYYY"
                          )} -
                      ${dayjs(e?.simpleEvents?.end).format("MMM D, YYYY")}`}
                    </p>
                  ) : null}
                  <div className="event_card_buttons">
                    <div className="event_card_button">
                      <Link to={`/event/${e?.slug}`}>
                        <button>More About</button>
                      </Link>
                    </div>
                    {e?.eventStartDate ? (
                      !dateInPast(e?.eventStartDate, today) ? (
                        e?.organizerUrl ? (
                          <div className="event_card_button">
                            <a
                              href={e?.organizerUrl}
                              target="__blank"
                              rel="noreferrer"
                            >
                              <button>RSVP</button>
                            </a>
                          </div>
                        ) : null
                      ) : e?.organizerUrl ? (
                        <div className="event_card_button">
                          <a
                            href={e?.organizerUrl}
                            target="__blank"
                            rel="noreferrer"
                          >
                            <button>Discussion</button>
                          </a>
                        </div>
                      ) : null
                    ) : null}
                    {e?.simpleEvents?.simpleEventOver === "" ||
                    e?.simpleEvents?.simpleEventOver === null ? (
                      <div className="event_card_button">
                        <a
                          href="https://cms.tinyml.org/tickets/"
                          target="__blank"
                          rel="noreferrer"
                        >
                          <button>Get Tickets</button>
                        </a>
                      </div>
                    ) : null}
                    {e?.cYAN?.eventSettings?.eventOver === "" ||
                    e?.cYAN?.eventSettings?.eventOver === null ? (
                      <div className="event_card_button">
                        <a
                          href="https://cms.tinyml.org/tickets/"
                          target="__blank"
                          rel="noreferrer"
                        >
                          <button>Get Tickets</button>
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpEventPage {
      nodes {
        title
        id
        date
        slug
        cYAN {
          start
          end
          eventSettings {
            eventOver
            collapseSchedule
            sectionSettings
          }
          featuredImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allWpSimpleEvent {
      nodes {
        slug
        id
        date
        title
        simpleEvents {
          simpleEventOver
          end
          start
          heroImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          timeSlots {
            startTime
            endTime
          }
        }
      }
    }
    allWpMeetupEvent {
      nodes {
        slug
        id
        date
        endDate
        startDate
        startTime
        startMinute
        startMeridian
        timezoneDiff
        content
        title
        organizerUrl
        organizerEventUrl
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "file-updates.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, maxHeight: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default Events
